// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  EndPoint: 'https://ros-proxy-dev1.antipasto.info/fastapi/',
  MenuApi: 'https://ros-proxy-dev1.antipasto.info/fastapi/menu/',
  OrderApi: 'https://ros-proxy-dev1.antipasto.info/fastapi/order/',  
  AuthDomain: 'ros-proxy-dev1.antipasto.info',
  ApiUser: 'api',
  ApiPass: 'UJ4Y4fHN3AbXnytV',
  HIS: 'https://demo01.antipasto.jp/room/',
  ga: 'G-78PC7Z72EP'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
