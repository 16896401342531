import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AmountComponent } from './pages/amount/amount.component';
import { MainComponent } from "./pages/main/main.component";
import { HistoryComponent } from "./pages/history/history.component";
import { WaitComponent } from './pages/cutlery/wait/wait.component';
import { CutleryComponent } from './pages/cutlery/cutlery.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full'
  },
  {
    path: 'main',
    component: MainComponent
  },
  {
    path: 'amount',
    component: AmountComponent
  },
  {
    path: 'cutlery',
    component: CutleryComponent
  },
  {
    path: 'history',
    component: HistoryComponent
  },
  {
    path: 'wait',
    component: WaitComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule
],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
