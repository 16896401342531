import { Component, OnInit } from '@angular/core';
import { Detail, History } from "../../shared/types";
import { fromEvent, Observable, Subscription } from "rxjs";
import { HistoryService } from "./history.service";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  items$!: Observable<Detail[]>;
  back: boolean = true;
  items: Detail[] = [];
  sum = 0;
  count = 0;
  index = 0;
  lang = '';
  interevalTimer = interval(3000);
  

  sub: Subscription[] = [];
  constructor(
    private historyService: HistoryService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient,
    private $gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {

    this.items$ = this.historyService.getHistory();
    this.historyService.getHistory().subscribe(data => {
      this.items = data;
      this.sum = this.items.reduce((a, b) => a + b.price * b.quantity, 0);
      this.count = this.items.reduce((a, b) => a + b.quantity, 0);
    });

    this.sub.push(
      this.interevalTimer.subscribe(()=>{
        this.items$ = this.historyService.getHistory();
        this.historyService.getHistory().subscribe(data => {
          this.items = data;
          this.sum = this.items.reduce((a, b) => a + b.price * b.quantity, 0);
          this.count = this.items.reduce((a, b) => a + b.quantity, 0);
        });       
      })
    )


    const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown')
    this.sub.push(keyDown$.subscribe(d => {
      switch (d.key) {
        case 'Enter':
          console.log(this.back);
          if (this.back) {
            this.router.navigate(['/']);
          }
          break;
      }
    }));
    this.lang = this.translate.currentLang;

    // Google Analyticsのページビュー
    this.$gaService.pageView('/history', '注文履歴')
  }

  ngOnDestroy(): void {
    this.sub.forEach(e =>{
      e.unsubscribe();
    })    
  }

}
