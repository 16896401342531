import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { debounce, debounceTime, fromEvent, map, Subscription, switchMap, takeUntil, timer } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ModeService } from "../../../shared/service/mode.service";
import { Cart, Item, Menu, 規格情報Modal } from "../../../shared/types";
import { CartService } from "../../../shared/service/cart.service";
import { ItemService } from "../../../shared/service/item.service";
import { ItemModalComponent } from "./item-modal/item-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { MenuService } from '../../../shared/service/menu.service';
import { FooterTypes } from "./footerTypes";
import { Router } from "@angular/router";
import { NowTimeService } from "../../../shared/service/nowTime.service";
import { AlertComponent } from "../../../shared/component/alert/alert.component";
import { environment } from 'src/environments/environment';
import { RoomNoService } from 'src/app/shared/roomNoService';
import { DelayService } from 'src/app/shared/service/delay.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  animations: [
    // animation triggers go here
    trigger('scrollMenu', [
      state('stop', style({
        left: '{{stopped_left}}'
      }), { params: { stopped_left: '0px' } }),

      state('scrolled', style({
        left: '{{left}}'
      }), { params: { left: '250px' } }),

      transition('* => scrolled', [
        animate('0.08s ease-out')
      ])
    ])]
})


export class MainMenuComponent implements OnInit {

  lang = '';

  @ViewChild('category_box') category_box: ElementRef | undefined

  constructor(private modeService: ModeService,
    private cartService: CartService,
    private itemService: ItemService,
    private dialog: MatDialog,
    private menuService: MenuService,
    private router: Router,
    private nowTimeService: NowTimeService,
    private cd: ChangeDetectorRef,
    private roomNoSercie: RoomNoService,
    private delayService: DelayService,
    public translate: TranslateService,
    private http: HttpClient,
    private translateService: TranslateService,
    private acriveRoute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService
  ) {
    this.HIS = environment.HIS
  }

  toggleLanguage() {
    if (this.translateService.currentLang === 'ja') {
      this.translateService.use('en');
    } else {
      this.translateService.use('ja');
    }
  }

  select_target: 'CategoryMenu' | 'menu' | 'footer' = 'CategoryMenu';
  menu_group: { id: number, category_name: string, category_type: number }[] = [];

  menu_list: Menu[] = []
  page_active = 0;
  menu_active = 0;
  menu_page = 0;
  menu_position = 0; //ページ内のポジション
  row_position = 0;
  left = '0px';
  category_left = 0;
  stopped_left = '0px';
  scrollMenu = '';
  isScroll = false;
  actiVeItem: Item | undefined;
  activeItemID = '';
  public price = this.cartService.合計金額$.asObservable();
  public count = this.cartService.個数$.asObservable();
  footer_target: 'cart' | 'allergy' | 'back' | '' = '';
  sub: Subscription | undefined;
  subArray: Subscription[] = [];
  before_row_position = 0;
  isBusinessTime$ = this.nowTimeService.isbusinessTimeBetweenSubject.asObservable();
  time = false;
  modalOn = false;
  HIS = '';
  room_no = 0;
  delayTime = 0;
  PageType = 1;

  intervalTimer = interval(5000)


  ngOnInit(): void {

    this.acriveRoute.queryParamMap
      .subscribe(params => {
        console.log('queryParamMap', params.get('lang'));
        this.lang = String(params.get('lang'));
        if (this.lang === 'ja' || this.lang === 'en') {
          console.log(this.lang)
          this.translateService.use(this.lang);
        }

      });

    this.room_no = this.roomNoSercie.roomNo
    this.menuService.getMenu().subscribe(d => {
      console.log('getMenu');
      this.menu_list = d;
      this.menu_group = d.map(d => {
        return {
          id: d.CategoryID,
          category_name: d.CategoryName,
          category_type: d.CategoryType
        }
      })
    },
      error => {
        //データの取得に失敗!
        this.modalOn = true;
        const dialogRef = this.dialog.open(AlertComponent, {
          data: {
            title: 'エラー：データの取得に失敗しました',
            message: '内線92番からご注文ください',
            goto: 'his'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.modalOn = false;
        });
      }
    )

    this.subArray.push(this.intervalTimer.subscribe(() => {
      this.subArray.push(
        this.delayService.getDelay().subscribe(d => {
          console.log('delay time:' + d.minute);
          this.delayTime = d.minute
        })
      )
    }))

    this.subArray.push(
      this.isBusinessTime$.subscribe(d => {
        this.time = d;
        this.cd.detectChanges();
      }));

    const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown')

    this.sub = keyDown$.subscribe((d) => {
      if (this.modalOn) return; // modalが開かれている時は何もしない
      if (this.modeService.mode === 'item') return; // modalが開かれている時は何もしない
      switch (d.key) {
        case 'ArrowLeft':
          console.log('←');
          if (this.select_target == 'CategoryMenu') {
            this.setCategoryPage(-1);
          } else if (this.select_target === 'menu') {
            // 左端なら何もしない
            this.setMenuFocus(-1);
            d.preventDefault()
            //return false;
          } else if (this.select_target === 'footer') {
            this.footerMove('left');
          }
          break;
        case 'ArrowRight':
          console.log('→');
          if (this.select_target == 'CategoryMenu') {
            this.setCategoryPage(1);
          } else if (this.select_target === 'menu') {
            this.setMenuFocus(1);
            d.preventDefault()
          } else if (this.select_target === 'footer') {
            this.footerMove('right');
          }
          break;
        case 'ArrowUp':
          console.log('↑');
          if (this.select_target === 'CategoryMenu') return;
          var PageType = this.menu_list[this.page_active]['PageType'];
          this.before_row_position = this.row_position;
          if ((this.row_position == 1 || PageType != 3) && this.select_target === 'menu') {
            // メニューのフォーカスは外す
            for (let menu_list of this.menu_list) {
              for (let menu of menu_list.Item) {
                menu.focus = false;
              }
            }
            this.row_position = 0;
          }
          if (this.row_position == 2 && this.select_target === 'menu') {
            console.log('row_position:2' + this.row_position);
            this.setMenuFocusRow(-1);
          }
          if (this.row_position === 3) {
            console.log('row_position:3' + this.row_position);
            this.row_position--;
          }
          if (this.select_target === 'footer' && PageType != 3) {
            this.row_position = 1;
          }
          // 各種if文の前にselect_targetを変更するとなんかバグる
          this.select_target = this.getNextTargetElement('up');
          break;

        case 'ArrowDown':
          console.log('↓');
          this.select_target = this.getNextTargetElement('down');
          // footerに行くのは↓押された時しか無いはず
          if (this.select_target === 'footer') {
            // とりあえずカートにフォーカス
            this.footer_target = 'cart';
          }

          if (this.row_position == 0 && this.select_target !== 'footer') {
            // 表示されているメニューのフォーカスをあてる(ポジションは固定)
            this.menu_active = 0;
            this.menu_list[this.page_active].Item[0].focus = true;
            this.actiVeItem = this.menu_list[this.page_active][`Item`][this.menu_active];
            this.row_position++;
            break;
          }

          if (this.row_position == 1 && this.menu_list[this.page_active].PageType == 3
            && this.select_target !== 'footer'
          ) {
            this.setMenuFocusRow(1);
            break;
          }
          break
        case 'Enter':
          if (this.select_target === 'menu') {
            this.clickItem(<number>this.actiVeItem?.ItemID)
          }
          if (this.select_target === 'footer' && this.footer_target === 'cart') {
            // 提供時間外なら遷移出来ない 変な時間にテストしたい場合はコメントアウトしてね☆彡
            if (!this.nowTimeService.isTimeBetween()) {
              this.dialog.open(AlertComponent, {
                data: {
                  title: '営業時間外です',
                  message: '営業時間内にご注文ください',
                }
              })
              return;
            }

            // カートに商品があれば遷移
            if (this.cartService.カート.length !== 0) {
              this.router.navigate(['/amount']);
              return;
            } else {
              // alert('カートに商品がありません');
              this.modalOn = true;
              const dialogRef = this.dialog.open(AlertComponent, {
                data: {
                  title: 'カートに商品がありません',
                  message: 'カートに商品を追加してください',
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                this.modalOn = false;
              });
            }
          }
          if (this.select_target === 'footer' && this.footer_target === 'allergy') {
            this.router.navigate(['/history']);
          }

          if (this.select_target === 'footer' && this.footer_target === 'back') {
            this.gotoHIS();
          }

          break;
      }
      console.log('target is' + this.select_target);
      console.log('Menu is ' + this.menu_position);
      console.log('Row Position is ' + this.row_position);
    });
    this.lang = this.translate.currentLang;

    // Google Analyticsのページビュー
    this.$gaService.pageView('/main', 'メインページ')
  }

  // 引数で渡された方向に移動する時どこにフォーカスをあてるかを返す
  // 基本上下にしか対応してない
  //
  getNextTargetElement(move: 'up' | 'down') {
    const obj = {
      CategoryMenu: { // target === categoryMenuの時の遷移先はmenuしかあり得ない
        up: 'CategoryMenu',
        down: 'menu'
      },
      menu: {
        up: {
          PageType1: 'CategoryMenu',
          PageType2: 'CategoryMenu',
          PageType3Position0: 'CategoryMenu',
          PageType3Position1: 'CategoryMenu',
          PageType3Position2: 'menu',
        },
        down: {
          PageType1: 'footer',
          PageType2: 'footer',
          PageType3Position1: 'menu',
          PageType3Position2: 'footer',
        }
      },
      footer: {
        up: 'menu',
        down: 'footer'
      }
    }
    const row = move === 'up' ? this.before_row_position : this.row_position;
    if (this.select_target === 'menu') {
      const pageType = this.menu_list[this.page_active].PageType;
      const position = 'Position' + row;
      const itemLength = this.menu_list[this.page_active].Item.length;
      if (pageType === 3) {
        if (row === 1 && itemLength <= 3 && move === 'down') {
          return obj[this.select_target][move][`PageType3Position2`];
        }
        //@ts-ignore
        return obj[this.select_target][move][`PageType3${position}`];
      }
      //@ts-ignore
      return obj[this.select_target][move][`PageType${pageType}`];
    } else {
      return obj[this.select_target][move]
    }


  }
  setMenuFocusRow(num: number) {
    // PageType3の時の上下ボタン動作

    if (num == 1) {
      // ↓ボタン
      if (this.menu_list[this.page_active].Item.length - 1 <= this.menu_active + 3) {
        this.menu_active = this.menu_list[this.page_active].Item.length - 1
      } else {
        this.menu_active = this.menu_active + 3;
      }
      // this.menu_active = this.menu_active + 3;
      this.row_position = 2
    } else {
      console.log("MenuActive -3");
      if (this.menu_active - 3 <= -1) {
        // よくわからんけどmenu_active0~2の時にここに来る事があってmenu_activeが-になる時がある
        // なんでだろう
        // とりあえず0にしておく
        this.menu_active = 0;
      } else {
        this.menu_active = this.menu_active - 3;
      }
      this.row_position = 1
    }

    for (let menu of this.menu_list[this.page_active]['Item']) {
      menu['focus'] = false
    }

    try {
      this.menu_list[this.page_active][`Item`][this.menu_active]['focus'] = true;
      this.actiVeItem = this.menu_list[this.page_active][`Item`][this.menu_active];
    } catch (e) {
      debugger
    }
    //this.left = String(-1735 * this.menu_page) + 'px';


  }
  setMenuFocus(num: number) {
    // メニューページ内の処理
    var PageType = this.menu_list[this.page_active]['PageType'];
    var nextPage = this.page_active;
    // 1ページ目の1番目の商品選んでる時は左にいけない
    if (num === -1 && this.menu_active === 0 && this.page_active === 0) return;
    // 最終ページの右端商品選んでる時は右にいけない
    const lastPageLength = this.menu_list[this.page_active]['Item'].length - 1;
    if (num === 1 && this.page_active === this.menu_list.length - 1 && (
      this.menu_active === 2 || this.menu_active === 5 || this.menu_active === lastPageLength)) {
      return;
    }

    for (let menu of this.menu_list[this.page_active]['Item']) {
      menu['focus'] = false
    }

    if (num == 1) {
      // → 押下時の動作

      if (PageType == 3 && this.menu_active == 2) {
        this.setCategoryPage(1)
        this.menu_active = 0;
        nextPage++;
      }
      else if (this.menu_list[this.page_active]['Item'].length > this.menu_active + 1) {
        this.menu_active++;
      } else {
        nextPage++;
        this.setCategoryPage(1)
        const NextPageType = this.menu_list[nextPage]['PageType'];
        const NextPageLength = this.menu_list[nextPage]['Item'].length - 1;
        if (this.row_position == 2 && NextPageType == 3 && NextPageLength > 2) {
          this.menu_active = 3;
        } else {
          this.menu_active = 0;
          this.row_position = 1;
        }
      }
    } else {
      //  ← 押下時の動作

      if (this.menu_active == 3) {
        console.log('下段 次のページの右端を選択する');
        //下の場合 次のページの右端を選択する
        this.menu_active = this.menu_list[this.page_active - 1].Item.length;

        //次のページのアイテム数が3以下の場合はrow_positionを1に固定する
        if (this.menu_list[this.page_active - 1].Item.length <= 3) {
          this.row_position = 1
        }

        this.setCategoryPage(-1)
        nextPage--;
      }

      if (this.menu_active > 0) {
        this.menu_active--;
      } else {
        //次のページの右端を選択する
        console.log(' 次のページの右端を選択する');
        nextPage--;
        var NextPageType = this.menu_list[nextPage]['PageType'];

        if (NextPageType == 3 && this.row_position == 1) {
          this.menu_active = 2;
        } else {
          this.menu_active = this.menu_list[this.page_active - 1].Item.length - 1;
        }
        this.setCategoryPage(-1)
      }
    }

    console.log('menu_acitve is' + this.menu_active);

    this.menu_list[nextPage]['Item'][this.menu_active]['focus'] = true;
    this.actiVeItem = this.menu_list[this.page_active][`Item`][this.menu_active];
    //this.left = String(-1735 * this.menu_page) + 'px';
  }

  setCategoryPage(num: number) {
    //カテゴリメニューの処理

    if (num == 1) {
      // →
      if (this.menu_group.length - 1 > this.page_active) {
        console.log(this.menu_group.length);
        console.log(this.page_active)
        this.page_active++;
      }
    } else {
      //←
      if (this.page_active > 0) {
        this.page_active--;
      }
    }

    var PageType = this.menu_list[this.page_active]['PageType'];

    //    if( PageType == 3 && this.row_position == 2){
    //      this.menu_list[this.page_active]['Menu'][3]['focus'] = true;
    //}

    //カテゴリメニューのスクロール
    this.category_left = ((this.page_active) / 4 | 0) * 1080
    this.category_box?.nativeElement.childNodes[0].childNodes[0].childNodes[0].scrollTo(this.category_left, 0)

    //console.log(this.category_box?.nativeElement.childNodes[0].childNodes[0].childNodes[0]);
    //console.log('category_left' + this.category_left);
    this.menu_page = this.page_active;
    this.left = String(-1620 * this.menu_page) + 'px';
    this.scrollMenu = "scrolled"

  }

  onAnimationEvent() {
    this.scrollMenu = 'stop'
    console.log('animation is done')
    this.stopped_left = this.left;
  }

  clickItem(itemID: number) {
    if (this.cartService.個数 >= 50) {
      this.dialog.open(AlertComponent, {
        data: {
          title: 'カートに商品が追加できません',
          message: 'カートの商品数が上限に達しました'
        }
      });
      return;
    }
    this.modeService.mode = 'item';
    // @ts-ignore
    const item: Item = this.menu_list.map(d => d.Item).flat().find(d => d.ItemID === itemID);
    // 商品が存在しないところでenter押された時の処理
    if (item == null) {
      this.modeService.mode = 'menu';
      return;
    }
    // 規格情報が無い場合はモーダルを出さずにカートに追加する
    console.log(item);
    if (item.規格情報 == null) {
      this.dialog.open(AlertComponent, {
        data: {
          title: 'カートに商品が追加されました',
          message: '引き続きルームオーダーサービスをお楽しみください',
        }
      });
      const el = this.cartService.カート.find(d => d.itemID === item.ItemID);
      // 既にカートにあるなら数値加算するだけ
      if (el) {
        this.cartService.addItemCount(item.ItemID);
      } else {
        const cartItem: Cart = {
          itemID: item.ItemID,
          itemName: item.Name,
          count: 1,
          price: item.Price,
          Time: item.Time,
          CategoryType: item.CategoryType
        }
        this.cartService.addItem(cartItem);
      }
      this.modeService.mode = 'menu';
      return;
    } else {
      if (item.KikakuName == null) {
        return alert('規格情報が存在しますが、規格名が存在しません。 ホテルに連絡してください。');
      }
      this.itemService.item = item;
      const data: 規格情報Modal = {
        parentId: item.ItemID,
        parentPrice: item.Price,
        parentName: item.Name,
        Time: item.Time,
        options: item.規格情報,
        optionName: item.KikakuName,
        CategoryType: item.CategoryType
      }
      const dialogRef = this.dialog.open(ItemModalComponent, {
        data
      });
      dialogRef.afterClosed().subscribe(() => {
        this.modeService.mode = 'menu';
      })
    }
  }

  // footerにカーソルが存在する時に左右押された時の制御
  footerMove(move: 'left' | 'right') {
    if (this.footer_target === '') {
      return;
    }
    const obj = {
      cart: {
        left: 'cart',
        right: 'allergy'
      },
      allergy: {
        left: 'cart',
        right: 'back'
      },
      back: {
        left: 'allergy',
        right: 'back'
      }
    }
    this.footer_target = obj[this.footer_target][move] as FooterTypes;
  }

  ngOnDestroy(): void {
    console.log('destroy');
    (this.sub as Subscription).unsubscribe();
    this.subArray.forEach(e => {
      e.unsubscribe();
    })
  }

  gotoHIS(): void {
    console.log('go?');
    window.location.href = this.HIS + this.room_no;
  }

  getFontSize(Name: string, Class: string, currentLang: string): string {
    //文字数に応じて、メニューのフォントサイズを変更する
    const length = Name.length;
    if (Class == '3x2') {
      return '28px';
    } else if (currentLang == 'en') {
      return '28px';
    } else if (Name.length < 20) {
      return '28px';
    } else if (Name.length < 30) {
      return '20px';
    } else {
      return '16px';
    }
  }

}
