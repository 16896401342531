import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {CartService} from "../../shared/service/cart.service";
import {finalize, firstValueFrom, fromEvent, lastValueFrom, Subscription} from "rxjs";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../shared/component/dialog/dialog.component";
import { DelayService } from 'src/app/shared/service/delay.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss']
})
export class AmountComponent implements OnInit {

  @ViewChild('amount_row') amount_row: ElementRef | undefined

  items = this.cart.カート;
  items$ = this.cart.カート$.asObservable();
  selectedItem = this.cart.カート[0];
  selectedElement: 'menu' | 'footer' = 'menu';
  // この使わないボタンの扱いはどーすｒｒｒｒｒｒｒｒｒｒｒｒ
  selectedFooterElement: 'confirm' | 'back' = 'confirm';
  rowIndex = 0;
  sub: Subscription | undefined;
  price$ = this.cart.合計金額$.asObservable();
  count$ = this.cart.個数$.asObservable();
  price = this.cart.合計金額;
  count = this.cart.個数;
  scrolled_y = 0;
  offsetTop = 0;
  購入なう = false;
  modalShow = false;
  delayTime = 0;
  timeArray:any = [];
  maxTime = 0;
  sumTime = 0;
  lang = '';

  constructor(
    private cart: CartService,
    private router: Router,
    private dialog: MatDialog,
    private delayServie: DelayService,
    public translate: TranslateService,
    private http: HttpClient,
    private $gaService: GoogleAnalyticsService
  ) {
  }

  ngOnInit(): void {
    if (this.cart.カート.length === 0)  {
      console.log(this.cart.カート.length + 'カートのlength');
      this.router.navigate(['']);
      return;
    }
    this.cart.sumPriceAndCount();
    this.price$.subscribe(d => {
      this.price = d;
      console.log(d);
    })
    this.count$.subscribe(d => {
      this.count = d;
    })
    this.cart.カート$.subscribe(d => {


      d.map(e =>{
        console.log(e);
        this.timeArray.push(e.Time);
        console.log('pushed')
      })

      const aryMax = function (a:number, b:number) {return Math.max(a, b);}
      this.maxTime = this.timeArray.reduce(aryMax);

      console.log(this.timeArray);
      if (this.items.length === d.length) return;
      this.items = d;
      if (this.rowIndex > 0) {
        this.rowIndex--;
      }


    })
    this.delayServie.getDelay().subscribe(d =>{
      this.delayTime = d.minute;

      this.sumTime = this.maxTime + this.delayTime;
    })

    const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown')
    this.sub = keyDown$.subscribe(async d => {
      if (this.modalShow) return;
      if (this.購入なう) return;
      switch (d.key) {
        case 'ArrowLeft':
          if (this.selectedElement === 'menu') {
            const data = this.cart.カート.find(d => d.itemID === this.items[this.rowIndex].itemID);
            if (!data) break;
            let result = false;
            console.log(data);
            if (data.count === 1) {
              this.modalShow = true;
              const dialogRef = this.dialog.open(DialogComponent, {
                data: {
                  title: 'カートから削除してもよろしいですか？',
                  message: `　`,
                  yesOrNo: 'no',
                }
              });
              result = await firstValueFrom(dialogRef.afterClosed());
              this.modalShow = false;
            }
            if ((data.count === 1 && result) || data.count > 1) {
              this.cart.subItemCount(this.items[this.rowIndex].itemID);
            }
            break;
          }
          if (this.selectedFooterElement === 'back') {
            this.selectedFooterElement = 'confirm';
          }
          break;
        case 'ArrowRight':
          if (this.selectedElement === 'menu') {
            this.cart.addItemCount(this.items[this.rowIndex].itemID);
            break;
          }
          if (this.selectedFooterElement === 'confirm') {
            this.selectedFooterElement = 'back';
          }
          break;
        case 'ArrowUp':
          if (this.rowIndex !== 0 && this.selectedElement !== 'footer') this.rowIndex--;
          this.selectedElement = 'menu'; // ↑押されたらmenuにしていい


          //セレクト済の行の位置を取得する
          let offsetTopUp = document.getElementsByClassName('cart_list_kazu')[this.rowIndex].getBoundingClientRect().top
          console.log('offsetTopUP:' + offsetTopUp);
          if (offsetTopUp < 300 && this.scrolled_y >= 83) {
            this.scrolled_y = this.scrolled_y - 83
            //スクロールさせる
            this.amount_row?.nativeElement.childNodes[0].childNodes[0].childNodes[0].scrollTo(0, this.scrolled_y)
            console.log('scrolled Up!');
          }
          d.preventDefault();
          break;
        case 'ArrowDown':
          if (this.rowIndex === this.cart.カート.length - 1) {
            this.selectedElement = 'footer';
            break
          }
          this.rowIndex++;

          //セレクト済の行の位置を取得する
          this.offsetTop = document.getElementsByClassName('cart_list_kazu')[this.rowIndex].getBoundingClientRect().top
          if (this.offsetTop > 700) {
            this.scrolled_y = this.scrolled_y + 83
            //スクロールさせる
            this.amount_row?.nativeElement.childNodes[0].childNodes[0].childNodes[0].scrollTo(0, this.scrolled_y)
            console.log('scrolled! Down');
          }
          d.preventDefault();
          break;
        case 'Enter':

        if (this.selectedFooterElement === 'back') {
          this.router.navigate(['/']);
        } else if (this.selectedFooterElement === 'confirm' && this.selectedElement === 'footer'){
          this.router.navigate(['/cutlery'])
        }
          break;
      }
    })
    this.lang = this.translate.currentLang;
    
    // Google Analyticsのページビュー
    this.$gaService.pageView('/amount', '数量の確認')
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  formatJST(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

}
