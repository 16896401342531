export interface Menu {
  PageID:       number;
  PageType:     number;
  CategoryName: string;
  CategoryType: number;
  Item:         Item[];
  CategoryID:   number;
}

export interface Item {
  ItemID:  number;
  Class:   string;
  Name:    string;
  Time:    number;
  SubName: string;
  Image:   string;
  Price:   number;
  focus:   boolean;
  規格情報?:   規格情報[];
  KikakuName?: string;
  CategoryType: string;
}

export interface 規格情報 {
  id:    number;
  price: number;
  namae: string;
  Image?: string;
}

export interface Cart {
  itemID: number;
  itemName: string;
  count:  number;
  price:  number;
  Time: number;
  規格情報id?: number;
  originId?: number;
  CategoryType: string;
}

export type 規格情報Modal = {
  parentId: number,
  parentName: string,
  optionName:string,
  parentPrice: number,
  Time: number,  
  options: 規格情報[]
  CategoryType:string;
}


export interface History {
  flag_success: boolean;
  data:         Datum[];
  flag_delivered: number;
  flag_canceled:  number;
  scheduleddelivery_at: Date;
}

export interface Datum {
  order_id:       number;
  room_id:        string;
  price_total:    number;
  memo:           string;
  created_at:     Date;
  updated_at:     Date;
  ordered_at:     Date;
  flag_delivered: number;
  delivered_at:   null;
  flag_canceled:  number;
  canceled_at:    null;
  detail:         Detail[];
  scheduleddelivery_at: Date;
}

export interface Detail {
  order_id:       number;
  item_id:        number;
  kikaku_id:      number | undefined;
  price:          number;
  quantity:       number;
  memo:           string;
  created_at:     string;
  updated_at:     Date;
  ordered_at:     Date;
  flag_delivered: number;
  delivered_at:   null;
  flag_canceled:  number;
  canceled_at:    null;
  item_name:      string;
  kikaku_name:    null | string;
  scheduleddelivery_at: string;
}
